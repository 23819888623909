<template>
    <w-simple-form
        :key="keyComponent"
        :disabled="isFormDisabled"
        :item="item"
        :fields="studentFormFields"
        :errors="responseErrors"
        deletable
        @save="onSave"
        @update="onUpdate"
        @delete="onDelete"
    ></w-simple-form>
</template>

<script>
import Inputmask from 'inputmask'
import { emailValidator, regexValidator, required } from '@/utils/validation'
import hasLevelsPropertyMixin from '@apps/school/mixins/hasLevelsPropertyMixin'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { memberFullName } from '@/components/Member/utils'
import { deleteEmployee, storeEmployee, updateEmployee } from '@apps/employee/api/employee'

export default {
    name: 'EmployeeForm',
    mixins: [ hasLevelsPropertyMixin ],
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            keyComponent: 0,
            isFormDisabled: false,
            responseErrors: {},
            fieldBaseBindings: {
                outlined: true,
                dense: true
            },
            formData: {}
        }
    },
    computed: {
        isFormItem() {
            return Object.keys(this.item).length > 0
        },
        studentFormFields() {
            let fields = []

            fields = fields.concat([
                {
                    name: 'email',
                    type: 'v-text-field',
                    props: {
                        label: 'E-mail',
                        ...this.fieldBaseBindings,
                        rules: [
                            required,
                            emailValidator
                        ]
                    }
                },
                {
                    name: 'last_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Last name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'first_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('First name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'middle_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Middle name'),
                        ...this.fieldBaseBindings
                    }
                },
                {
                    name: 'gender',
                    type: 'w-gender-input',
                    props: {
                        ...this.fieldBaseBindings,
                        locale: this.$store.getters.locale
                    }
                },
                {
                    name: 'birthday',
                    type: 'w-birthday-input',
                    props: {
                        ...this.fieldBaseBindings,
                        locale: this.$store.getters.locale,
                        firstDayOfWeek: this.$store.getters.firstDayOfWeek
                    }
                },
                {
                    name: 'phone',
                    type: 'v-text-field',
                    on: {
                        focus: this.initPhoneMask
                    },
                    props: {
                        id: 'student-phone-input',
                        label: this.$trans('Phone'),
                        ...this.fieldBaseBindings,
                        rules: [
                            v => regexValidator(v, '^\\+38 \\(0([0-9]{2})\\) ([0-9]{3})-([0-9]{2})-([0-9]{2})$')
                        ]
                    }
                }
            ])

            return fields
        }
    },
    watch: {
        item() {
            this.keyComponent++
        }
    },
    methods: {
        initPhoneMask() {
            const selector = document.getElementById('student-phone-input')
            const im = new Inputmask('+38 (099) 999-99-99')

            if (selector) {
                im.mask(selector)
            }
        },
        async onSave(data) {
            this.responseErrors = {}
            this.isFormDisabled = true

            for (const key in data) {
                if (data[key] === null) {
                    delete data[key]
                }
            }

            try {
                const response = await storeEmployee(data)
                this.isFormDisabled = false
                this.$emit('save', response.data)
                this.$emit('submit', response.data)
            } catch (e) {
                this.isFormDisabled = false

                if (e.response.data.errors) {
                    this.responseErrors = e.response.data.errors
                }
            }
        },
        async onUpdate(data, item) {
            this.isFormDisabled = true

            for (const key in data) {
                if (data[key] === null) {
                    delete data[key]
                }
            }

            try {
                const response = await updateEmployee(item.uuid, data)
                this.isFormDisabled = false
                this.$emit('updated', response.data)
                this.$emit('submit', response.data)
            } catch (error) {
                this.responseErrors = error.response.data.errors || {}
                this.isFormDisabled = false
            }
        },
        async onDelete() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${memberFullName(this.item)}`
            }))

            if (!isConfirm) {
                return
            }

            try {
                await deleteEmployee(this.item.uuid)
                this.$emit('submit')
                this.$emit('deleted')
            } catch (error) {}
        }
    }
}
</script>

<style lang=scss>

</style>
