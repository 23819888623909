<template>
    <content-wrapper>
        <w-data-table
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :items-per-page="collection.per_page"
            :server-items-length="collection.total"
            :page="collection.current_page"
            searchable
            @change:options="fetchItems"
        >

            <template #actions>
                <w-btn-action
                    :text="$trans('Add employee')"
                    icon="ADD"
                    @click="onShowForm(null)"
                />
            </template>

            <template #[`item.last_name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{ item }">
                <div class="text-truncate">
                    <v-btn
                        :disabled="!$auth.isSuperAdmin() && (item.created_by !== $auth.user.uuid)"
                        icon
                        color="success"
                        @click="onShowForm(item)"
                    >
                        <w-icon size="20" value="mdi-pencil"/>
                    </v-btn>
                </div>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div v-if="isFormItem" class="px-4 pt-3">
                <v-card
                    outlined
                    class="d-flex align-center pa-3"
                >
                    <div class="d-flex justify-center">
                        <member-avatar-form
                            :member="item"
                            member-type="employee"
                            :size="60"
                            rounded
                        />
                    </div>
                    <div>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item | memberFullName }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $trans('Employee') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-card>
            </div>
            <div class="pa-4">
                <employee-form
                    :key="formKey"
                    :item="item"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import MemberFullName from '@/components/Member/MemberFullName'
import ContentWrapper from '@/components/ContentWrapper'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import { getEmployeesCollection } from '@apps/employee/api/employee'
import EmployeeForm from '@apps/employee/components/EmployeeForm'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import MemberAvatarForm from '@apps/auth/components/MemberAvatarForm'

export default {
    name: 'EmployeesManage',
    components: {
        MemberAvatarForm,
        EmployeeForm,
        ContentWrapper,
        MemberFullName
    },
    mixins: [
        hasCurrentSchoolPropertyMixin,
        hasMemberFullNameFilterMixin
    ],
    computed: {
        isFormItem() {
            return Object.keys(this.item).length > 0
        },
        asideTitle() {
            return Object.keys(this.item).length === 0
                ? this.$trans('Add employee')
                : this.$trans('Update employee')
        },
        headers() {
            return [
                {
                    text: this.$trans('Full name'),
                    value: 'last_name',
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    data() {
        return {
            isAsideDrawer: false,
            loading: false,
            collection: {
                data: []
            },
            item: {},
            formKey: 0
        }
    },
    methods: {
        fetchItems(payload) {
            this.loading = true

            payload = payload || {}

            if (!payload.perPage) {
                payload.perPage = 25
            }

            payload.with = 'access'

            getEmployeesCollection(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
        },
        onShowForm(item) {
            this.item = item || {}
            this.isAsideDrawer = true
            this.formKey++
        },
        onSubmit(data) {
            this.item = {}
            this.isAsideDrawer = false
            this.loading = false
            this.fetchItems()
        }
    }
}
</script>

<style lang="scss">

</style>
